<template>
    <div class="content">
        <!-- 头部 -->
        <header>
            <img onclick="window.history.go(-1)" src="../../assets/img/go.png" alt="">
            <span style="padding-left: 33%" class="fs">教育公益</span>
        </header>
        <!-- 第二部分 -->
        <div class="photo_img">
            <p class="fb">教育公益</p>
        </div>
        <!-- 第三部分 -->
        <div class="graphic">
            <div class="graphic_content">
                <div class="graphic_text">
                    <span class="donation fb">
                        再佳学向浙江侨联捐赠
                    </span>
                    <br>
                    <span class="hundred fb">
                        100万套
                    </span>
                    <span class="donation fb">
                        学习服务和内容
                    </span>
                    <img src="../../assets/img/教育公益.png" alt="">
                    <p class="fs">习近平总书记关于侨务的重要论述为根本遵循。要在“政治立侨”、“事业兴侨”、“文化育侨”、“服务助侨”、“教育带侨”上有更大作为。浙江省委常委、统战部部长熊建平，中国侨联副主席、省政协副主席吴晶，省侨联党组书记、主席连小敏等领导见证，在杭州举行了北京再佳学科技的捐赠仪式，助力所有在国外的侨胞可以通过再佳学双语应景教育了解并学习中国优秀教育文化内容。首批捐赠10万套，共捐赠100万套。
                    </p>
                </div>
            </div>
        </div>

        <!-- 尾部 -->
        <footerEn></footerEn>
    </div>
</template>

<script>

export default {
    data() {
        return {
        };
    },
    mounted() {
        if (!/Android|iPhone|iPod/i.test(navigator.userAgent)) {
            this.$router.push('/win')
        } else {
            window.addEventListener('resize', this.remScale());
           
        }
    },
    methods: {
        remScale() {
            var winW = document.documentElement.clientWidth;
            winW = winW > 750 ? 750 : winW;
            document.documentElement.style.fontSize = winW / 750 * 100 + 'px'
        }
    }
};
</script>

<style scoped lang="scss">
    @import "../../assets/css/win_en.scss";
</style>
